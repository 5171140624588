
.flex-grid {
    margin-left: -10px;
    margin-right: -10px;
}

.card-grid {
    max-width: 100%;
    box-sizing: border-box;

}

.chart-wrap {
    position: relative;
    overflow: hidden;
}

@media (min-width: 800px) {
    .card-grid {
        max-width: 100%;
        box-sizing: border-box;
    }
}

.card-content {
    position: relative;
    height: 100%;
}

.card-statistics-icon {
    position: absolute;
    right: 0px;
    top: 0;
    width: 42px;
    height: 42px;
    display: flex;
    -moz-box-align: center;
    align-items: center;
    -moz-box-pack: center;
    justify-content: center;
    border-radius: 12px;
    color: #fff;
    /* background-color: var(--amplify-colors-brand-primary-60); */
    background-color: #0b97ac;
    font-size: 22px;
}

@media (max-width: 480px) {
    .card-statistics-icon {
        width: 30px; /* Size on smaller mobile devices */
        height: 30px; /* Size on smaller mobile devices */
        font-size: 15px; /* Size on smaller mobile devices */
    }
}
.card-title {
    font-size: 18px;
    color: #0b97ac;
    font-weight: bold;
}
.card-statistics-amount {
    font-size: 30px;
}
.card-statistics-budget {
    font-size: 14px;
    color: #0b97ac;
    font-weight: bold;
}
.card-statistics-budgetamount {
    font-size: 30px;
    color: #0b97ac;
}

td.money {
    text-align: right;
    font-family: "Courier New", Courier, monospace;
    font-weight: bold;
}
span.budget {
    color: #0b97ac;
}
td.money-total {
    text-align: right;
    font-family: "Courier New", Courier, monospace;
    font-weight: bold;
}
th.money {
    text-align: right;

}
td.rowheader {
    font-weight: bold;
    font-size: 14px;
}
.click-link {
    cursor: pointer;
    color: #0b97ac;
    text-decoration: underline;
}

.compact-table {
    max-width: 100%; /* Adjust as needed */
    font-size: 0.9em; /* Smaller font size */
    padding: 5px; /* Reduce padding */
}

  .budget {
    position: relative;
    color: gray; /* Default color for goal text */
  }
  
  .goal-value {
    color: blue; /* Initial color for the goal value */
    position: relative;
    display: inline-block; /* Ensures the goal value behaves like a block for better alignment */
    vertical-align: middle; /* Vertically aligns the number with other content */
  }
  
  .goal-value:hover {
    color: darkblue; /* Change color on hover */
    cursor: pointer;
  }
  
  .goal-value::after {
    content: 'Goal';
    position: absolute;
    top: 20px; /* Moves the "Goal" text above the number */
    left: 50%; /* Centers the "Goal" text horizontally */
    transform: translateX(-50%); /* Adjust for horizontal centering */
    opacity: 0;
    background-color: yellow;
    color: black;
    padding: 2px 5px;
    border-radius: 5px;
    white-space: nowrap; /* Prevents text wrapping */
    transition: opacity 0.3s ease;
    pointer-events: none; /* Ensures the tooltip doesn't interfere with mouse events */
    z-index: 10; /* Ensures the "Goal" text is on top */
  }
  
  .goal-value:hover::after {
    opacity: 1;
  }

  .apexcharts-custom-tooltip {
    position: absolute;
    transform: translateY(15px); /* Adjust this value as needed */
    background-color: #ffffff;
    padding: 5px;
    border-radius: 3px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    z-index: 10;
  }
  